.project {
    width: 100%;
    cursor: pointer;
    padding: 0 5px;
    position: relative;
    overflow: hidden;
    color: #fff;
}

.project h3 {
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    z-index: 2;
}

.project h4 {
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    z-index: 2;
    font-family: 'Lato', Helvetica, sans-serif;
}

.project .thumb {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    opacity: .03;
    transition: transform 1.5s ease-in, opacity .8s;
}

.project:hover .thumb {
    transition: transform 4s ease-out, opacity 2s;
    opacity: .8;
    transform: scale(1.2);
}

.project.feelboard, .live.feelboard {
    background-color: #5496EB;
}

.project.feelboard .thumb {
    background-image: url("../images/feelboard-header.png");
}

.project.storyline, .live.storyline {
    background-color: #2C2DD0;
}

.project.storyline .thumb {
    background-image: url("../images/storyline-header.png");
}

.project.bonariv, .live.bonariv {
    background-color: #3BD2CB;
}

.project.bonariv .thumb {
    background-image: url("../images/bonariv-header.jpg");
}

.project.rosette, .live.rosette {
    background-color: #2A2E43;
}

.project.rosette .thumb {
    background-image: url("../images/rosette-header.png");
}

.project.meezi, .live.meezi {
    background-color: #0253FD;
}

.project.meezi .thumb {
    background-image: url("../images/meezi-header.jpg");
}

.project.motionsense, .live.motionsense {
    background-color: #71838A;
}

.project.motionsense .thumb {
    background-image: url("../images/motionsense-header.jpg");
}

@media only screen and (min-width: 580px) {
    .project h3 {
        font-size: 28px;
        font-weight: 700;
    }

    .project h4 {
        font-size: 16px;
        font-weight: 300;
    }
}