.case-study .case-section {
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-flow: column;
}

.case-study .case-section div:first-child {
    margin-top: 80px;
}

.case-study .case-section .case-image {
    height: 50%;
    width: 100%;
    display: flex;
}

.center {
    justify-content: center;
    align-items: center;
}

.case-study .case-section .info {
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    padding: 0 10%;
}

.case-study .case-section .case-image img {
    height: 90%;
    width: auto;
}


.grow {
    flex-grow: 1;
}

.case-study .case-section .info.text-shrink p {
    font-size: 14px;
}

.case-study .header {
    background-size: cover;
    background-position: center center;
}

.case-study.meezi .header {
    background-image: url('../images/meezi-header.jpg');
}

.case-study.rosette .header {
    background-image: url('../images/rosette-header.png');
}

.case-study.feelboard .header {
    background-image: url('../images/feelboard-header.png');
}

.case-study.bonariv .header {
    background-image: url('../images/bonariv-header.jpg');
}

.case-study.storyline .header {
    background-image: url('../images/storyline-header.png');
}

.case-study.motionsense .header {
    background-image: url('../images/motionsense-header.jpg');
}

.case-study .header .headline {
    padding: 5%;
    width: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
}

.case-study .headline h1 {
    color: #fff;
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 42px;
}

.case-study .headline h2 {
    color: #eee;
    font-weight: 400;
    font-size: 20px;
    font-family: 'Lato', Helvetica, sans-serif;

}

.case-study .case-section {
    position: relative;
    background-color: #f6f6f6;
}

.case-study .live button {
    color: #f6f6f6;
    background: none;
    border: none;
    font-size: 36px;
    padding: 20px 40px;
    cursor: pointer;
    font-family: inherit;
}

.case-study .live button:hover {
    color: #fff;
    opacity: 0.7;
    transition: all .5s;
}

.case-study .live button::after {
    content: '\25ba';
    font-size: 30px;
    margin-left: 10px;
    vertical-align: middle;
}

.case-study .case-section h2 {
    font-size: 24px;
    text-align: left;
    width: 100%;
}

.case-study .case-section p {
    font-size: 20px;
    color: #636363;
    line-height: 26px;
    max-width: 650px;
    text-align: left;
}

.case-study .case-section p.center{
    text-align: center;
}

.case-study .case-section .info {
    align-items: center;
}

.case-study .case-section .case-video video {
    object-fit: fill;
    width: 100%;
    height: 100%;
}

.case-study .case-section .case-video, .case-study .case-section .case-video video {
    display: none;
}

.case-study .case-section .info.half.stretch {
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
}

.case-study .case-section .background {
    background-size: cover;
}

/********
ROSETTE
 *******/

.rosette-section-1 .background {
    background-image: url('../images/rosette-section-1.jpg');
    width: 100%;
    background-position: top center;
}

.case-section .case-image.half img {
    height: 100%;
    width: auto;
    margin-top: 100px;
}

.case-section.rosette-section-3 .info {
    background-color: #4B4E5D;
}

.rosette-section-3 .info h2 {
    color: #E2415A;
}

.rosette-section-3 .info p {
    color: #C0C4D8;
}

.rosette-section-4 .background {
    background-image: url('../images/rosette-section-4.png');
    background-position: top center;
}

/********
FEELBOARD
 *******/

.feelboard-section-1, .feelboard-section-2, .feelboard-section-4 {
    background-color: #f6f6f6;
}

.case-section.feelboard-section-3 {
    background-color: #150729;
    color: #fff;
}

.feelboard-section-3 .info p {
    color: #fff;
}

.feelboard .info .boxes {
    text-align: center;
    padding: 10px 0 30px 0;
}

.feelboard .info .boxes .box {
    width: 240px;
    height: 32px;
    text-align: center;
    font-weight: 300;
    margin-top: 5px;
    font-size: 14px;
    padding-top: 6px;
    color: #080858;
    box-shadow: 4px 4px 30px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
}

.feelboard .info .boxes .arrow {
    width: 16px;
    height: 9px;
    background-image : url("../images/feelboard-arrow@2x.png");
    margin: 8px auto;
    background-size: cover;
}

.case-study .case-section.feelboard-section-4 .background {
    background-image: url("../images/feelboard-section-4.png");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
}

/********
STORYLINE
 *******/

.case-section.storyline-section-1 .case-image {
    background-image: url('../images/storyline-section-1.png');
    background-size: cover;
    background-position: center center;
}

/********
BONARIV
 *******/

.case-study .case-section.bonariv-section-1 .case-image {
    display: none;
}

.case-study .case-section.bonariv-section-5 .info {
    height: 100%;
}

.bonariv-section-5 {
    justify-content: flex-end;
}

/********
MEEZI
 *******/

.case-study .case-section.half.meezi-section-1 .case-image img {
    height: 90%;
    width: auto;
}

.case-study .case-section.meezi-section-2 .case-image img {
    width: 90%;
    height: auto;
}

.meezi-section-3 {
    background-image: url('../images/meezi-section-3.png');
    background-size: cover;
}

.case-study .case-section.meezi-section-3 h2 {
    text-align: center;
    margin: 0 auto 15px auto;
}


.case-study .case-section.meezi-section-3 .info {
    height: 100%;
    width: 100%;
    color: #fff;
    text-align: center;
    justify-content: end;
    margin-left: auto;
    margin-right: auto;
    padding: 0 5%;
}

.case-study .case-section.meezi-section-3 .info p {
    color: #fff;
    font-weight: 500;
    text-align: center;
    margin: 0 auto;
}

/********
MOTIONSENSE
 *******/

.motionsense-section-2 {
    background-image: url('../images/motionsense-section-2.png');
    background-size: cover;
    background-position: right center;
    color: #fff;
}

.case-study .case-section.motionsense-section-2 .info p {
    color: #fff;
    font-weight: 500;
}

@media only screen and (min-width: 760px) {
    .case-study .headline h1 {
        font-size: 52px;
    }

    .case-study .live button {
        font-size: 50px;
        font-family: 'Roboto', Helvetica, sans-serif;
    }

    .case-study .case-section {
        flex-flow: row-reverse;
    }

    .case-study .case-section.reverse {
        flex-flow: row;
    }

    .case-study .case-section.column {
        flex-flow: column;
    }

    .case-study .case-section.half .case-image {
        width: 50%;
        height: 100%;
    }

    .case-study .case-section.half .case-image img {
        width: 90%;
        height: auto;
    }

    .case-study .case-section div:first-child {
        margin-top: unset;
    }

    .case-study .case-section.half .info {
        align-items: flex-start;
        padding: 0 10%;
        width: 50%;
    }

    .case-study .case-section p {
        text-align: left;
    }

    .case-study .case-section .info {
        align-items: flex-start;
    }

    .case-study .case-section .info.margin-top {
        margin-top: 80px;
    }

    .case-study .case-section .info.text-shrink p {
        font-size: 20px;
    }

    .case-study .case-section .info.text-center {
        align-items: center;
    }

    .case-study .case-section h2 {
        font-size: 52px;
        text-align: left;
    }

    .case-study .case-section .case-video, .case-study .case-section .case-video video {
        display: block;
    }

    /********
    FEELBOARD
     *******/

    .feelboard-section-4 .case-image.half img {
        height: 100%;
        width: auto;
    }

    .feelboard .info .boxes {
        padding: 0 50px 0 0;
    }

    .feelboard .info .boxes .box {
        width: 370px;
        height: 64px;
        margin-top: 20px;
        font-size: 24px;
        padding-top: 15px;
    }

    .feelboard .info .boxes .arrow {
        width: 32px;
        height: 18px;
        background-image : url("../images/feelboard-arrow@2x.png");
        margin: 20px auto 0 auto;
        background-size: cover;
    }

    /********
    STORYLINE
     *******/

    .case-section.storyline-section-2 .case-image.half img {
        width: 80%;
    }

    /********
    BONARIV
     *******/

    .case-study .case-section.bonariv-section-1 .case-image {
        display: flex !important;
    }

    .bonariv-section-2 .info.content, .bonariv-section-3 .info.content, .bonariv-section-4 .info.content {
        padding-top: unset;
    }

    /********
    MEEZI
     *******/
    .case-study .case-section.meezi-section-3 div:first-child {
        margin-top: 80px;
    }

    /********
    MOTIONSENSE
     *******/

    .case-study .motionsense .case-section.half {
        flex-flow: row nowrap !important;
    }

}