.about .section {
    flex-flow: column wrap !important;
    height: 100%;
}

.about .half {
    min-width: 100% !important;
    min-height: 50% !important;
}

.about .profile {
    background-image: url('../images/profile.jpg');
    background-size: cover;
    background-position: center;
    display: none;
}

.about .text {
    padding-left: 20px;
    padding-right: 20px;
    min-width: 100% !important;
    min-height: 100% !important;
    justify-content: center;
    line-height: 30px;
    text-align: center;
}

.about .text p {
    color: #868686;
}

.about .text p a {
    text-decoration: none;
    color: #0253fd;
}

.about .text p strong {
    font-weight: 700;
}

.about .text p a:hover {
    text-decoration: underline;
}

.about .text p a:visited {
    color: #0253fd;
}

@media only screen and (min-width: 580px) {

    .about .section{
        flex-flow: row wrap !important;
    }

    .about .half {
        min-width: 50% !important;
        min-height: 100% !important;
    }

    .about .text {
        padding-left: 40px;
        padding-right: 40px;
        text-align: left;
    }
}