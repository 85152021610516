.section.banner {
    z-index: 2;
    background-color: #000;
    display: flex !important;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    overflow: hidden;
}

.header-slogan {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.slogan-container {
    padding: 100px 0 0 10%;
}

.banner h1, .banner h2 {
    font-size: 48px;
    text-align: left;
    padding: 0 10px;
    color: #f6f6f6;
    opacity: 0;
}

.banner h2 {
    font-size: 24px;
    font-family: 'Lato', Helvetica, sans-serif;
    font-weight: 400;
}

.header-slogan.loaded h1, .header-slogan.loaded h2 {
    opacity: 1;
}

.header-slogan.loaded h1.hi {
    transition: opacity 2s;
}

.header-slogan.loaded h1.i {
    transition: opacity 2s;
    transition-delay: 1s;
}

.header-slogan.loaded h1.am {
    transition: opacity 2s;
    transition-delay: 1.2s;
}

.header-slogan.loaded h1.ido {
    transition: opacity 1.5s;
    transition-delay: 1.5s;
}

.header-slogan.loaded h2 {
    transition: opacity 1.5s;
    transition-delay: 2.5s;
}

.banner video {
    width: 260%;
    height: auto;
    margin-bottom: -30%;
}

.projects {
    min-height: 100%;
}

.half {
    width: 50%;
}

.webCaptionContainer {
    width: 100vw;
    position: absolute;
    height: 100vh;
    text-align: center;
    color: white;
}

.webCaption {
    display: flex;
    height: 100vh;
    justify-content: center;
}

.scroll-downs {
    position: absolute;
    right: 0;
    bottom: 30px;
    left: 0;
    margin: auto;
    width :34px;
    height: 55px;
}
.mousey {
    width: 3px;
    padding: 10px 15px;
    height: 35px;
    border: 2px solid #fff;
    border-radius: 25px;
    opacity: 0.75;
    box-sizing: content-box;
}
.scroller {
    width: 3px;
    height: 10px;
    border-radius: 25%;
    background-color: #fff;
    animation-name: scroll;
    animation-duration: 1.8s;
    animation-timing-function: cubic-bezier(.15,.41,.69,.94);
    animation-iteration-count: infinite;
}

@keyframes scroll {
    0% { opacity: 0; }
    10% { transform: translateY(0); opacity: 1; }
    100% { transform: translateY(15px); opacity: 0;}
}

@media only screen and (min-width: 720px) {

    .section.banner {
        align-items: center;
        justify-content: flex-end;
        width: 100%;
    }

    .slogan-container {
        height: 100%;
        justify-content: center;
        padding: 0 0 0 10%;
    }

    .banner video {
        width: 100%;
        margin-right: -36%;
        margin-bottom: 0;
    }

    .banner h1 {
        font-size: 120px;
    }

    .banner h2 {
        font-size: 40px;
    }
}