@import url('https://fonts.googleapis.com/css?family=Lato:300,400|Roboto:700&display=swap');

* {
    box-sizing: border-box;
}

html, body {
    margin: 0;
    padding: 0;
    color: #1e1e23;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    min-width: 300px;
}

body {
    min-height: 100%;
    font-family: 'Lato', Helvetica, sans-serif;
}

h1, h2, h3, h4 {
    font-family: 'Roboto', Helvetica, sans-serif;
    display: block;
    margin: 0;
}

p {
    font-family: 'Lato', Helvetica, sans-serif;
}

a:hover, a:focus, a:active,
button:hover, button:focus, button:active{
    outline: none;
    border: none;
}

.fp-tableCell {
    box-sizing: border-box;
    width: 100%;
    display: inherit !important;
    flex-flow: inherit;
    flex: inherit;
    justify-content: inherit;
    align-items: inherit;
}

.sticky-header {
    position: fixed;
    left: 20px;
    top: 20px;
    z-index: 3;
}

.sticky-header a, .sticky-header a:hover {
    color: #fff;
    text-decoration: none;
    text-transform: none;
}

#fullpage {
    width: 100%;
}

.logo-bar {
    position: fixed;
    width: 100%;
    z-index: 9;
    background: none;
    left: 15px;
    top: 32px;
}

.logo-bar img {
    height: 30px;
    width: auto;
    position: absolute;
}

.logo-bar.bright .logo-bright, .logo-bar.symbol .logo-symbol, .logo-bar.dark .logo-dark {
    visibility: visible;
    opacity: 1;
    transition: opacity 1s ease;
}

.logo-bar.bright .logo-dark, .logo-bar.bright .logo-symbol,
.logo-bar.dark .logo-bright, .logo-bar.dark .logo-symbol,
.logo-bar.symbol .logo-bright, .logo-bar.symbol .logo-dark
{
    visibility: hidden;
    opacity: 0;
    transition: opacity 1s ease;
}


.logo-bar.bright #burger-button .burger-bar {
    background: #fff;
    transition: oapcity 1s ease;
}

.logo-bar.dark #burger-button .burger-bar {
    background: #000;
    transition: opacity 1s ease;
}

.content {
    padding-top: 100px;
}

@media only screen and (min-width: 580px) {
    .logo-bar {
        left: 30px;
    }
}