#burger-button {
    position: fixed;
    right: 15px;
    top: 38px;
    z-index: 99;
    cursor: pointer;
}

#burger-button .burger-bar {
    background: #fff;
    height: 2px;
    width: 28px;
    margin-bottom: 5px;
    transition: transform .4s ease;
}

.logo-bar.dark #burger-button .burger-bar, .logo-bar.bright #burger-button .burger-bar {
    transition: transform .4s ease;
}

.logo-bar.bright #burger-button.open .burger-bar {
    background: #000;
}

#burger-button.open .burger-bar {
    background: #000;
}

#burger-button.open .burger-bar:first-child {
    transform: translateY(7px) rotate(45deg);
}

#burger-button.open .burger-bar:nth-child(2) {
    visibility: hidden;
}

#burger-button.open .burger-bar:nth-child(3) {
    transform: translateY(-7px) rotate(-45deg);
}

#burger-button:hover .burger-bar,
.logo-bar.dark #burger-button:hover .burger-bar,
.logo-bar.symbol #burger-button:hover .burger-bar,
.logo-bar.bright #burger-button:hover .burger-bar {
    background: #F522D5;
}

.logo-bar #burger-button.open:hover .burger-bar {
    background: #000;
}

.logo-bar #burger-button .burger-bar {
    box-shadow: 0 0 16px 0 rgba(0,0,0,0.3);
}

#burger-button:active .burger-bar, #burger-button.open:active .burger-bar, .logo-bar.dark #burger-button:active .burger-bar {
    background: #fff;
}

#menu-overlay {
    background: rgba(0,0,0, .3);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    visibility: hidden;
}

#menu-overlay.open {
    visibility: visible;
}

#menu {
    display: block;
    width: 100%;
    position: fixed;
    top: 0;
    right: -100%;
    height: 100%;
    background: #fff;
    padding: 40px 30px;
    opacity: 0;
    transition: transform ease .3s, opacity .5s;
    z-index: 90;
}

#menu.open {
    transform: translateX(-100%);
    opacity: 1;
}

#menu nav a {
    color: #000;
    text-decoration: none;
    height: 50px;
    font-size: 30px;
    font-weight: 700;
    display: block;
}

#menu nav a:hover {
    color: #F522D5;
}

#menu .secondary {
    margin-top: 40px;
}

#menu .secondary .menu-item {
    color: #868686;
    font-weight: 500;
    font-size: 20px;
    height: 30px;
    display: block;
}

@media only screen and (min-width: 580px) {
    #menu {
        width: 400px;
        right: -400px;
    }
    #menu.open{
        transform: translateX(-400px);
    }

    #burger-button {
        right: 30px;
    }
}